<template>
  <b-form>

    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName + '.section.medical_exams') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <div v-if="caseType!=='COVID'">
            <b-row>
              <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.patient_positive') }}</span>
            </b-row>
            <b-row>
              <b-col
                cols="2"
                md="2"
              >
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.hiv') }}</span>
                  <b-form-checkbox
                    v-model="elementData.patient_positive_hiv"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                md="2"
              >
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.vhc') }}</span>
                  <b-form-checkbox
                    v-model="elementData.patient_positive_vhc"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                md="2"
              >
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.vhb') }}</span>
                  <b-form-checkbox
                    v-model="elementData.patient_positive_vhb"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                md="2"
              >
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.syphilis') }}</span>
                  <b-form-checkbox
                    v-model="elementData.patient_positive_syphilis"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col
              cols="12"
              xl="12"
            >
              <Lab-tests-tabs-list
                :case_id="elementData.id"
                :title="$t('labtests.section.followup')"
                type="FOLLOWUP"
              />
              <Lab-tests-list
                :case_id="elementData.id"
                :title="$t('labtests.section.employee')"
                type="PATIENT"
              />
              <Lab-tests-list
                :case_id="elementData.id"
                :title="$t('labtests.section.source')"
                type="SOURCE"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BFormCheckbox, BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LabTestsList from './labtest/list.vue'
import LabTestsTabsList from './labtest/tabsList.vue'

export default {
  components: {
    LabTestsList, LabTestsTabsList, BFormCheckbox, BCard, BRow, BCol, BCardHeader, BCardBody, BFormGroup, BForm, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    booleanList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    caseType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      resourcesElementName: 'case',
      consultResourcesElementName: 'consult',
    }
  },
  watch: {
    'elementData.patient_exams_ok_id': function (val) {
      this.elementData.patient_exams_ok = this.booleanList.find(element => element.id == val)
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>
