<template>
  <b-form v-if="elementData.main_diagnosis_id !== undefined">
    <validation-observer
      ref="elementForm"
      #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">{{
              $t(consultResourcesElementName + '.section.evoluion')
            }}</span>
            <span
              v-if="!disabled"
              class="text-danger"
              >*</span
            >
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <b-form-group label-for="recommendations_evolution">
                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(
                      consultResourcesElementName +
                        '.object.recommendations_evolution'
                    )
                  "
                  :rules="disabled ? '' : 'required'">
                  <b-form-textarea
                    id="evolution"
                    v-model="elementData.recommendations_evolution"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!isHepa">
            <b-col
              cols="12"
              md="12">
              <b-form-group label-for="diagnosis_impression">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(
                    consultResourcesElementName + '.object.diagnosis_impression'
                  )
                }}</span
                ><span
                  v-if="!disabled"
                  class="text-danger"
                  >*</span
                >

                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(
                      consultResourcesElementName +
                        '.object.diagnosis_impression'
                    )
                  "
                  :rules="disabled ? '' : 'required'">
                  <b-form-textarea
                    id="observations"
                    v-model="elementData.diagnosis_impression"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card v-if="isHepa">
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">{{
              $t(consultResourcesElementName + '.section.observations')
            }}</span>
            <span class="text-danger">*</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.status')
              }}</span>
              {{ elementData.consult_case_status_value }}
            </b-col>
          </b-row>

          <div id="source_fields">
            <b-row>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.close_hiv')
                  }}</span>
                  <b-form-checkbox
                    v-model="elementData.close_hiv"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled" />
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.close_vhc')
                  }}</span>
                  <b-form-checkbox
                    v-model="elementData.close_vhc"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled" />
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.close_vhb')
                  }}</span>
                  <b-form-checkbox
                    v-model="elementData.close_vhb"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled" />
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.close_syphilis')
                  }}</span>
                  <b-form-checkbox
                    v-model="elementData.close_syphilis"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled" />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="consult_bio_danger_id"
                :root-element-data="elementData"
                :element-label="
                  $t(consultResourcesElementName + '.object.biological_danger')
                "
                :element-selection-list="biologicalDangerList"
                :inline="true"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="consult_antibodies_status_id"
                :root-element-data="elementData"
                :element-label="
                  $t(
                    consultResourcesElementName +
                      '.object.consult_antibodies_status'
                  )
                "
                :element-selection-list="antobodiesStatusList"
                :inline="true"
                :exec="update_visibility"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <div
            id="antibodies_size_fields"
            ref="antibodies_size_fields"
            class="hidden-div mb-2">
            <b-row>
              <b-col
                cols="6"
                md="6">
                <span class="font-weight-bold info_title">{{
                  $t(
                    consultResourcesElementName +
                      '.object.antibody_status_gt_ten'
                  )
                }}</span>
                <b-form-checkbox
                  v-model="elementData.antibody_status_gt_ten"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                  value="1"
                  unchecked-value="0"
                  :disabled="disabled" />
              </b-col>
              <b-col
                cols="6"
                md="6">
                <span class="font-weight-bold info_title">{{
                  $t(
                    consultResourcesElementName +
                      '.object.antibody_status_lt_ten'
                  )
                }}</span>
                <b-form-checkbox
                  v-model="elementData.antibody_status_lt_ten"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                  value="1"
                  unchecked-value="0"
                  :disabled="disabled" />
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <b-form-group label-for="recommendations_observations">
                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(
                      consultResourcesElementName +
                        '.object.recommendations_observations'
                    )
                  "
                  :rules="disabled ? '' : 'required'">
                  <b-form-textarea
                    id="observations"
                    v-model="elementData.recommendations_observations"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">{{
              $t(consultResourcesElementName + '.section.diagnosis')
            }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="4">
              <feather-icon
                icon="StarIcon"
                class="mr-75" />
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.main_diagnosis')
              }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.main_diagnosis_id"
                  :options="diagnosisList"
                  label="key"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled" />
              </div>
            </b-col>
            <b-col cols="8">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.main_diagnosis_id"
                  :options="diagnosisList"
                  label="value"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled" />
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4">
              <feather-icon
                icon="StarIcon"
                class="mr-75" />
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.secondary_diagnosis')
              }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.secondary_diagnosis_id"
                  :options="diagnosisList"
                  label="key"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled" />
              </div>
            </b-col>
            <b-col cols="8">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.secondary_diagnosis_id"
                  :options="diagnosisList"
                  label="value"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled" />
              </div>
            </b-col>
          </b-row>
          <b-row v-if="isCovid">
            <b-col
              cols="12"
              md="12"
              class="mt-1">
              <b-form-group label-for="analisis">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.analisis')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.analisis')"
                  rules="required">
                  <b-form-textarea
                    id="plan"
                    v-model="elementData.analisis"
                    maxlength="6000" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mt-1">
              <b-form-group label-for="plan">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.plan')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.plan')"
                  rules="required">
                  <b-form-textarea
                    id="plan"
                    v-model="elementData.plan_text"
                    maxlength="6000" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mt-1">
              <b-form-group label-for="recommendations">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.recommendations')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(consultResourcesElementName + '.object.recommendations')
                  "
                  rules="required">
                  <b-form-textarea
                    id="recommendations"
                    v-model="elementData.recommendations"
                    maxlength="6000" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mt-1">
              <b-form-group label-for="annotations">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.annotations')
                }}</span>
                <b-form-textarea
                  id="annotations"
                  v-model="elementData.annotations"
                  maxlength="6000" />
              </b-form-group>
            </b-col>
          </b-row>

          <div v-if="isHepa">
            <b-row>
              <b-col
                cols="3"
                md="3"
                lg="3">
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.follow_dates')
                }}</span>
              </b-col>
              <!-- Right Col: Table -->
              <b-col
                cols="4"
                md="4"
                lg="4">
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.follow_1')
                }}</span>
                <date-picker
                  size="lg"
                  :element-required="false"
                  element-field="follow_date_1"
                  :root-element-data="elementData"
                  element-label=""
                  :disabled="true" />
              </b-col>
              <b-col
                cols="4"
                md="4"
                lg="4">
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.follow_2')
                }}</span>
                <date-picker
                  size="lg"
                  :element-required="false"
                  element-field="follow_date_2"
                  :root-element-data="elementData"
                  element-label=""
                  :disabled="true" />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="3"
                md="3"
                lg="3" />
              <!-- Right Col: Table -->
              <b-col
                cols="4"
                md="4"
                lg="4">
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.follow_3')
                }}</span>
                <date-picker
                  size="lg"
                  :element-required="false"
                  element-field="follow_date_3"
                  :root-element-data="elementData"
                  element-label=""
                  :disabled="true" />
              </b-col>
              <b-col
                cols="4"
                md="4"
                lg="4">
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.follow_4')
                }}</span>
                <date-picker
                  size="lg"
                  :element-required="false"
                  element-field="follow_date_4"
                  :root-element-data="elementData"
                  element-label=""
                  :disabled="true" />
              </b-col>
            </b-row>
          </div>

          <b-row v-if="!isHepa">
            <b-col
              cols="4"
              md="4">
              <b-form-group label-for="documentType">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(consultResourcesElementName + '.object.status')
                }}</span
                ><span
                  v-if="!disabled"
                  class="text-danger"
                  >*</span
                >
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.status')"
                  :rules="disabled ? '' : 'required'">
                  <div class="v-select-wrapper">
                    <v-select
                      v-model="elementData.consult_case_status_id"
                      :options="internalConsultCaseStatusList"
                      label="value"
                      value="id"
                      :reduce="(option) => option.id"
                      :placeholder="$t('list.defaultOption')"
                      :filter-by="filterWithoutAccents"
                      :disabled="disabled"
                      @input="onCaseStatusChange" />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>
<script>
import {
  BFormTextarea,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import filtersMixin from '@/libs/filtersMixin'

export default {
  components: {
    BFormTextarea,
    RadioSelectPicker,
    BFormCheckbox,
    DatePicker,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [filtersMixin],
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    diagnosisList: {
      type: Array,
      required: true,
    },
    consultCaseStatusList: {
      type: Array,
      required: true,
    },
    biologicalDangerList: {
      type: Array,
      required: true,
    },
    antobodiesStatusList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHepa: {
      type: Boolean,
      required: true,
    },
    isCovid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      internalConsultCaseStatusList: [],
      resourcesElementName: 'case',
      consultResourcesElementName: 'consult',
    }
  },
  watch: {
    'elementData.close_syphilis': function () {
      this.update_consult_case_status_value()
    },
    'elementData.close_hiv': function () {
      this.update_consult_case_status_value()
    },
    'elementData.close_vhb': function () {
      this.update_consult_case_status_value()
    },
    'elementData.close_vhc': function () {
      this.update_consult_case_status_value()
    },
  },
  mounted() {
    this.internalConsultCaseStatusList = this.consultCaseStatusList.filter(
      (element) => element.key === 'CLOSED' || element.key === 'FOLLOW'
    )
    this.update_visibility()

    // Set diagnosis placeholder
    this.diagnosisList[0].key = 'Código'
    this.diagnosisList[0].value = 'Diagnóstico'
    if (!this.elementData.main_diagnosis_id) {
      this.elementData.main_diagnosis_id = this.diagnosisList[0].id
    }
    if (!this.elementData.secondary_diagnosis_id) {
      this.elementData.secondary_diagnosis_id = this.diagnosisList[0].id
    }
    if (this.isHepa) {
      this.update_consult_case_status_value()
    }
  },
  methods: {
    onCaseStatusChange() {
      const status = this.internalConsultCaseStatusList.find(
        (element) => element.id == this.elementData.consult_case_status_id
      )
      this.elementData.consult_case_status_value = status.value
      this.elementData.consult_case_status_key = status.key
    },
    update_consult_case_status_value() {
      // Set case status options to closed and follow
      let toCloseCase = false
      if (
        this.elementData.close_hiv == '1' &&
        this.elementData.close_vhb == '1' &&
        this.elementData.close_vhc == '1' &&
        this.elementData.close_syphilis == '1'
      ) {
        toCloseCase = true
      }
      this.elementData.consult_case_status_element =
        this.consultCaseStatusList.find((element) =>
          toCloseCase ? element.key === 'CLOSED' : element.key === 'FOLLOW'
        )
      this.elementData.consult_case_status_id =
        this.elementData.consult_case_status_element.id
      this.elementData.consult_case_status_value =
        this.elementData.consult_case_status_element.value
      this.elementData.consult_case_status_key =
        this.elementData.consult_case_status_element.key
    },
    update_visibility() {
      const element = document.getElementById('antibodies_size_fields')
      if (element) {
        if (
          this.elementData.consult_antibodies_status_id &&
          this.antobodiesStatusList.find(
            (element) =>
              this.elementData.consult_antibodies_status_id === element.id
          ).key === 'KNOWN'
        ) {
          element.classList.remove('hidden-div')
        } else {
          element.classList.add('hidden-div')
        }
      }
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
