<template>
  <div>
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label-for="vaccine_services"
          >
            <feather-icon
              class="mr-75"
              icon="StarIcon"
            />
            <span class="font-weight-bold">{{ $t(consultResourcesElementName + '.object.vaccine_services') }}</span>
            <validation-provider
              #default="{ errors }"
              :name="$t(consultResourcesElementName + '.object.vaccine_services')"
            >
              <v-select
                v-model="vaccineData.service_id"
                :options="vaccineServicesList"
                label="value"
                value="id"
                :reduce="option => option.id"
                :placeholder="getPlaceholderValue(vaccineServicesList)"
                @input="onServiceChange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
          md="6"
        >
          <b-form-group
            label-for="vaccine_scheme"
          >
            <feather-icon
              class="mr-75"
              icon="StarIcon"
            />
            <span class="font-weight-bold">{{ $t(consultResourcesElementName + '.object.vaccine_scheme') }}</span>
            <validation-provider
              #default="{ errors }"
              :name="$t(consultResourcesElementName + '.object.vaccine_scheme')"
            >
              <v-select
                v-model="vaccineData.vaccine_scheme_id"
                :options="vaccineSchemesList"
                label="value"
                value="id"
                :reduce="option => option.id"
                :placeholder="getPlaceholderValue(vaccineSchemesList)"
                @input="onSchemeChange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="6"
        >
          <b-col
            cols="12"
            md="12"
          >
            <date-picker
              ref="first_dosage_id"
              :element-label="$t(consultResourcesElementName + '.object.vaccine_first_dossage')"
              :element-required="false"
              :root-element-data="vaccineData"
              class=""
              element-field="first_dosage"
            />
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <date-picker
              :element-label="$t(consultResourcesElementName + '.object.vaccine_second_dosage')"
              :element-required="false"
              :root-element-data="vaccineData"
              element-field="second_dosage"
            />
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <date-picker
              :element-label="$t(consultResourcesElementName + '.object.vaccine_third_dossage')"
              :element-required="false"
              :root-element-data="vaccineData"
              element-field="third_dosage"
            />
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <date-picker
              :element-label="$t(consultResourcesElementName + '.object.vaccine_fourth_dossage')"
              :element-required="false"
              :root-element-data="vaccineData"
              element-field="fourth_dosage"
            />
          </b-col>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
          md="6"
        >
          <div
            id="vaccine_antibodies_text"
            class="hidden-div"
          >906223 - Hepatitis B ANTICUERPOS S [ANTI-HBs]
            SEMIAUTOMATIZADO O AUTOMATIZADO
          </div>
        </b-col>
        <b-col
          cols="6"
          md="6"
        >
          <b-col
            cols="12"
            md="12"
          >
            <date-picker
              :element-label="$t(consultResourcesElementName + '.object.vaccine_antibody_dossage')"
              :element-required="false"
              :root-element-data="vaccineData"
              element-field="antibody_dosage"
            />
          </b-col>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import {
  BCol,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    DatePicker,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    vaccineData: {
      type: Object,
      required: true,
    },
    vaccineServicesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      vaccineSchemesList: [],
      hepatitisB: ['993503'], // por defecto activan la primera opcion, y agrega campo anticuerpo 906223 - Hepatitis B ANTICUERPOS S [ANTI-HBs] SEMIAUTOMATIZADO O AUTOMATIZADO
      otros: ['993510', '993103', '993106'], // solo activa el esquema de otros
    }
  },
  watch: {
  },
  mounted() {
    this.vaccineSchemesList = [{ id: 'FIRST', value: '0d, 1m, 6m' }, { id: 'SECOND', value: '0d, 7d, 28d, 6m' }, { id: 'OTHER', value: 'Otro' }]
  },
  methods: {
    onSchemeChange() {
      // this.$root.$emit('update-scheme')
      const _self = this
      switch (_self.vaccineData.vaccine_scheme_id) {
        case 'FIRST':
          _self.vaccineData.first_dosage = moment(_self.vaccineData.accident_date, 'DD/MM/YYYYTHH:mm:ss').toDate()
          _self.vaccineData.second_dosage = moment(_self.vaccineData.accident_date, 'DD/MM/YYYYTHH:mm:ss').add(1, 'months').toDate()
          _self.vaccineData.third_dosage = moment(_self.vaccineData.accident_date, 'DD/MM/YYYYTHH:mm:ss').add(6, 'months').toDate()
          _self.vaccineData.fourth_dosage = undefined
          break
        case 'SECOND':
          _self.vaccineData.first_dosage = moment(_self.vaccineData.accident_date, 'DD/MM/YYYYTHH:mm:ss').toDate()
          _self.vaccineData.second_dosage = moment(_self.vaccineData.accident_date, 'DD/MM/YYYYTHH:mm:ss').add(7, 'days').toDate()
          _self.vaccineData.third_dosage = moment(_self.vaccineData.accident_date, 'DD/MM/YYYYTHH:mm:ss').add(28, 'days').toDate()
          _self.vaccineData.fourth_dosage = moment(_self.vaccineData.accident_date, 'DD/MM/YYYYTHH:mm:ss').add(6, 'months').toDate()
          break
        default:
          _self.vaccineData.first_dosage = undefined
          _self.vaccineData.second_dosage = undefined
          _self.vaccineData.third_dosage = undefined
          _self.vaccineData.fourth_dosage = undefined
          break
      }
    },
    onServiceChange() {
      this.vaccineData.service = this.vaccineServicesList.find(element => element.id == this.vaccineData.service_id)
      const { cups } = this.vaccineData.service
      console.log('cups', this.vaccineData.service.cups)
      if (cups && cups.length > 0) {
        const antibodies_text = document.getElementById('vaccine_antibodies_text')
        if (this.otros.includes(cups)) {
          this.vaccineSchemesList = [{ id: 'OTHER', value: 'Otro' }]
          this.vaccineData.vaccine_scheme_id = 'OTHER'
          antibodies_text.classList.add('hidden-div')
        } else if (this.hepatitisB.includes(cups)) {
          this.vaccineSchemesList = [{ id: 'FIRST', value: '0d, 1m, 6m' }, { id: 'SECOND', value: '0d, 7d, 28d, 6m' }, { id: 'OTHER', value: 'Otro' }]
          this.vaccineData.vaccine_scheme_id = 'FIRST'
          antibodies_text.classList.remove('hidden-div')
        }
        this.onSchemeChange()
      }
    },
    getPlaceholderValue(list) {
      const placeholderOption = list.find(option => option.id === null)
      return placeholderOption ? placeholderOption.value : ''
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
