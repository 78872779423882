<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Médicos</span>
        </h5>
        <b-form-group>
          <b-form-radio-group
            id="specific_calendar"
            v-model="event.doctor_id"
            :options="calendarOptions"
            text-field="full_name"
            value-field="id"
            class="mb-1"
            :class="`custom-control-primary`"
            @change="onChangeDoctor"
          />
        </b-form-group>
      </div>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Tipo de consulta</span>
        </h5>
        <b-form-group>
          {{ get_type() }}
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BFormRadioGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    isNewEvent: {
      type: Boolean,
      require: true,
    },
    event: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      full_name: '',
    }
  },
  methods: {
    get_type() {
      if (this.event && this.event.type_id) {
        const element = this.dateTypeList.find(l => l.id == this.event.type_id)
        return element ? element.value : ''
      }
      return ''
    },
    onChangeDoctor(new_id) {
      this.$parent.changeDoctor(new_id)
    },
  },
  setup(props, { emit }) {
    const {
      calendarOptions,
      dateTypeList,
      selectedCalendars,
      selectedDateType,
      checkAll,
      case_id,
    } = useCalendarSidebar(emit)

    return {
      calendarOptions,
      dateTypeList,
      selectedCalendars,
      selectedDateType,
      checkAll,
      case_id,
    }
  },
}
</script>

<style>

</style>
