<template>
  <div>

    <b-form @submit.prevent="save">

      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                class="mr-0 mr-sm-50"
                icon="StarIcon"
                size="25"
                variant="primary"
              />
              <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName + '.section.vaccine') }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="city"
                >
                  <feather-icon
                    class="mr-75"
                    icon="StarIcon"
                  />
                  <span
                    class="font-weight-bold"
                  >{{ $t(consultResourcesElementName + '.object.vaccine_laboratory_lab') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(consultResourcesElementName + '.object.vaccine_laboratory_lab')"
                  >
                    <v-select
                      v-model="orderData.clinical_laboratory_lab_id"
                      :options="localVaccineLaboratoryList"
                      label="name"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(localVaccineLaboratoryList)"
                      @input="onLaboratoryChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="vaccine_department"
                >
                  <feather-icon
                    class="mr-75"
                    icon="StarIcon"
                  />
                  <span
                    class="font-weight-bold"
                  >{{ $t(consultResourcesElementName + '.object.vaccine_department') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(consultResourcesElementName + '.object.vaccine_department')"
                  >
                    <v-select
                      v-model="orderData.clinical_department_id"
                      :options="vaccineDepartmentList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(vaccineDepartmentList)"
                      @input="onDepartmentChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="city"
                >
                  <feather-icon
                    class="mr-75"
                    icon="StarIcon"
                  />
                  <span class="font-weight-bold">{{ $t(consultResourcesElementName + '.object.vaccine_city') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(consultResourcesElementName + '.object.vaccine_city')"
                  >
                    <v-select
                      v-model="orderData.clinical_city_id"
                      :options="vaccineCityList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(vaccineCityList)"
                      @input="onCityChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="vaccine_laboratory_headquarter"
                >
                  <feather-icon
                    class="mr-75"
                    icon="StarIcon"
                  />
                  <span
                    class="font-weight-bold"
                  >{{ $t(consultResourcesElementName + '.object.vaccine_laboratory_headquarter') }}</span>
                  <span
                    v-if="requiredVaccineHeadquarter"
                    class="text-danger"
                  >*</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(consultResourcesElementName + '.object.vaccine_laboratory_headquarter')"
                    :rules="requiredVaccineHeadquarter ? 'required' : ''"
                  >
                    <v-select
                      v-model="orderData.clinical_laboratory_headquarter_id"
                      :options="vaccineHeadquarterList"
                      label="name"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(vaccineHeadquarterList)"
                      @input="onHeadquarterChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                xl="12"
              >
                <b-form-group
                  label-for="vaccine_laboratory_headquarter_address"
                >
                  <feather-icon
                    class="mr-75"
                    icon="StarIcon"
                  />
                  <span
                    class="font-weight-bold"
                  >{{ $t(consultResourcesElementName + '.object.vaccine_laboratory_headquarter_address') }}</span>

                  <b-form-input
                    id="vaccine_laboratory_headquarter_address"
                    v-model="address"
                    :disabled="true"
                    :placeholder="$t(consultResourcesElementName + '.object.vaccine_laboratory_headquarter_address')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <vaccine
              :vaccine-data="vaccineData"
              :vaccine-services-list="vaccineServicesList"
            />
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="service_observations"
                >

                  <feather-icon
                    class="mr-75"
                    icon="StarIcon"
                  />
                  <span
                    class="font-weight-bold"
                  >{{ $t(consultResourcesElementName + '.object.service_observations') }}</span>

                  <validation-provider
                    #default="{ errors }"
                    :name="$t(consultResourcesElementName + '.object.service_observations')"
                  >
                    <b-form-textarea
                      id="observations"
                      v-model="orderData.observations"
                      max="250"
                      maxlength="250"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                id="cancel-button"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="primary"
                @click="hide_modal()"
              >
                {{ $t('actions.back') }}
              </b-button>
              <b-button
                :disabled="invalid"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                type="submit"
                variant="primary"
              >
                {{ $t('actions.save') }}
              </b-button>
            </b-row>
          </b-card-body>
        </b-card>
      </validation-observer>
    </b-form>
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Vaccine from './Vaccine.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    Vaccine,
    BFormTextarea,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    vaccineDepartmentList: {
      type: Array,
      required: true,
    },
    vaccineCityList: {
      type: Array,
      required: true,
    },
    vaccineLaboratoryList: {
      type: Array,
      required: true,
    },
    vaccineHeadquarterList: {
      type: Array,
      required: true,
    },
    vaccineServicesList: {
      type: Array,
      required: true,
    },
    requiredVaccineHeadquarter: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      vaccineResourcesElementName: 'vaccines',
      servicesResourcesElementName: 'services',
      localVaccineLaboratoryList: [],
      address: '',
      vaccineData: {},
    }
  },
  watch: {},
  beforeDestroy() {
    this.$root.$off('save-vaccine')
  },
  mounted() {
    if (this.orderData.services.length > 0) {
      this.vaccineData = this.orderData.services[0]
    } else {
      this.vaccineData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        services: [],
        accident_date: this.orderData.accident_date,
        first_dosage: undefined,
        second_dosage: undefined,
        third_dosage: undefined,
        fourth_dosage: undefined,
        antibody_dosage: undefined,
      }
    }
    this.$root.$emit('fetch-vaccine-cities', this.orderData.clinical_department_id)
    this.$root.$emit('fetch-vaccine-headquarters', this.orderData.clinical_laboratory_lab_id)
    this.localVaccineLaboratoryList = [...this.vaccineLaboratoryList]
  },
  methods: {
    saveVaccine(vaccineData) {
      const _self = this
      _self.orderData.services[0] = vaccineData
    },
    onDepartmentChange() {
      this.orderData.clinical_city_id = null
      this.$root.$emit('fetch-vaccine-cities', this.orderData.clinical_department_id)
      this.$root.$emit('filter-vaccine-headquarters-by-department', this.orderData.clinical_laboratory_lab_id, this.orderData.clinical_department_id)
    },
    onCityChange() {
      // Filter laboratory list by city
      this.orderData.clinical_laboratory_headquarter_id = null
      this.$root.$emit('filter-vaccine-headquarters-by-city', this.orderData.clinical_laboratory_lab_id, this.orderData.clinical_city_id)
    },
    onLaboratoryChange() {
      this.orderData.clinical_department_id = null
      this.orderData.clinical_city_id = null
      this.orderData.clinical_laboratory_headquarter_id = null
      this.$root.$emit('fetch-vaccine-headquarters', this.orderData.clinical_laboratory_lab_id)
      const laboratory = this.vaccineLaboratoryList.find(element => element.id == this.orderData.clinical_laboratory_lab_id)
      this.address = laboratory ? laboratory.address : ''
    },
    onHeadquarterChange() {
      this.address = this.orderData.clinical_laboratory_headquarter_id ? this.vaccineHeadquarterList.find(element => element.id == this.orderData.clinical_laboratory_headquarter_id).address : this.address
    },
    get_edit_button_id(key) {
      return `edit_vaccine_button${key}`
    },
    get_delete_button_id(key) {
      return `delete_vaccine_button${key}`
    },
    hide_modal() {
      this.$root.$emit('bv::hide::modal', 'modal-vaccines')
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          _self.saveVaccine(_self.vaccineData)
          _self.$root.$emit('save-order', _self.orderData)
          _self.$root.$emit('bv::hide::modal', 'modal-vaccines')
        }
      })
    },
    getPlaceholderValue(list) {
      const placeholderOption = list.find(option => option.id === null)
      return placeholderOption ? placeholderOption.value : ''
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
