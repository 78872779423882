import axiosClient from '@/utils/axiosClient'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    doctor_id: undefined,
    calendarOptions: [],
    selectedCalendars: [],
    selectedDateType: [],
    dateTypeList: [],
    selectedAvailabilities: [
      //   {
      //   daysOfWeek: [ 2, 3, 4, 5 ], // Monday - Friday
      //   startTime: '08:00', // 8am
      //   endTime: '18:00' // 6pm
      // }
    ],
    abortController: null,
    availabilities: {},
  },
  getters: {},
  mutations: {
    SET_CALENDAR_OPTIONS(state, val) {
      state.calendarOptions = val
    },
    SET_DATE_TYPE_LIST(state, val) {
      state.dateTypeList = val
    },
    SET_SELECTED_DOCTORS(state, val) {
      state.selectedCalendars = val
    },
    SET_SELECTED_DOCTOR(state, val) {
      state.doctor_id = val
    },
    SET_SELECTED_DATE_TYPE(state, val) {
      state.selectedDateType = val
    },
    SET_SELECTED_AVAILABILITIES(state, val) {
      state.selectedAvailabilities = val
    },
    SET_AVAILABILITY(state, val) {
      state.availabilities = val
    },
    SET_CONTROLLER(state, controller) {
      state.abortController = controller
    },
    CLEAR_CONTROLLER(state) {
      state.abortController = null
    },
  },
  actions: {
    fetch_key_value(ctx, type) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/type_value/${type}`, {
            params: {
              deep: 1,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDoctors(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/availability', { params: { query: '' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDoctorAvailability(ctx, { newDoctor }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/availability/${newDoctor}`, {})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEvents(ctx, { doctor_id, start, end }) {
      if (Number.isInteger(doctor_id)) {
        const start_string = start ? start.toISOString() : null
        const end_string = end ? end.toISOString() : null
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/v1/process/case_date/doctor/${doctor_id}?start=${start_string}&end=${end_string}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return []
    },
    async fetchCalendar({ commit, state }, { doctor_id, start, end }) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }

      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      if (!Number.isInteger(doctor_id)) {
        commit('CLEAR_CONTROLLER')
        return Promise.resolve([])
      }

      let start_string = start
      let end_string = end

      if (!(start instanceof Date) || !(end instanceof Date)) {
        return Promise.resolve([])
      }
      if (start instanceof Date) {
        start_string = start.toISOString()
      }
      if (end instanceof Date) {
        end_string = end.toISOString()
      }

      return new Promise((resolve, reject) => {
        const axiosInterceptor = Boolean(process.env.VUE_APP_REDIS_SCHEDULE) === true ? axiosClient : axios
        axiosInterceptor
          .get(`/api/v1/process/case_date/doctor/${doctor_id}?start=${start_string}&end=${end_string}`, {
            signal: controller.signal,
          })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
