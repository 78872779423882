<template>
  <div>
    <b-form-group
      label-for="services"
    >
      <feather-icon
        icon="StarIcon"
        class="mr-75"
      />
      <span class="font-weight-bold">{{ elementLabel }}</span>
      <div>
        <b-form-input
          v-model="filterCriteria"
          class="search-input"
          type="search"
          @keyup="selectItem()"
        />
      </div>
      <div>
        <b-form-select
          v-model="elementData"
          :options="filteredList"
          value-field="id"
          text-field="value"
          disabled-field="notEnabled"
          multiple
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormSelect, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormSelect, BFormGroup, BFormInput,
  },
  props: {
    rootElementData: {
      type: Object,
      required: true,
    },
    elementLabel: {
      type: String,
      required: true,
    },
    elementField: {
      type: String,
      required: true,
    },
    availableList: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      filterCriteria: '',
      filteredList: [],
    }
  },
  computed: {
    elementData: {
      get() {
        if (this.rootElementData[this.elementField]) {
          return this.rootElementData[this.elementField]
        }
        return undefined
      },
      set(newValue) {
        this.rootElementData[this.elementField] = newValue
      },
    },
  },
  mounted() {
    console.log('list', this.availableList)
    this.filteredList = this.availableList
  },
  methods: {
    selectItem() {
      console.log('selecting', this.filterCriteria)
      this.filteredList = this.availableList.filter(element => element.value.toLowerCase().includes(this.filterCriteria.toLowerCase()))
    },
    rowSelected(rowArray) {
      // No rows or 1 row can be selected
      if (rowArray.length === 1) {
        this.$emit('item-selected', rowArray[0])
        this.filterCriteria = rowArray[0][this.display]
      }
    },
  },
}
</script>
