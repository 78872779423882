var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[(_vm.elementData.consult_name)?_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.section.base_info'))+": "+_vm._s(_vm.elementData.consult_name))])],1)]):_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.section.base_info'))+": "+_vm._s(_vm.elementData.consult_type))])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mr-1 ml-1",attrs:{"cols":"4","md":"4"}},[_c('b-row',[_c('b-form-group',{attrs:{"label-for":"consult_type"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.consult_type')))]),(!_vm.disabled)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.consult_type'),"rules":_vm.disabled ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"v-select-wrapper"},[_c('v-select',{attrs:{"options":_vm.consultTypeList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption'),"filter-by":_vm.filterWithoutAccents,"disabled":_vm.disabled},model:{value:(_vm.elementData.consult_type_id),callback:function ($$v) {_vm.$set(_vm.elementData, "consult_type_id", $$v)},expression:"elementData.consult_type_id"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.consult_date')))]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.elementData.final_consult_date))+" ")],1),(false)?_c('b-row',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.consult_date')))]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.elementData.consult_date))+" ")],1):_vm._e()],1),_c('b-col',{staticClass:"mr-1 ml-1",attrs:{"cols":"4","md":"4"}},[_c('b-row',[_c('b-form-group',{attrs:{"label-for":"consult_status"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.consult_status')))]),(!_vm.disabled)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t(
                        _vm.consultResourcesElementName + '.object.consult_status'
                      ),"rules":_vm.disabled ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{staticClass:"v-select-wrapper"},[_c('v-select',{attrs:{"options":_vm.consultStatusList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption'),"filter-by":_vm.filterWithoutAccents,"disabled":_vm.disabled},on:{"input":_vm.onConsultStatusChange},model:{value:(_vm.elementData.consult_status_id),callback:function ($$v) {_vm.$set(_vm.elementData, "consult_status_id", $$v)},expression:"elementData.consult_status_id"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-form-group',{attrs:{"label-for":"consult_status"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.case_type')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.case_type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.consultCaseTypeList,"value-field":"id","text-field":"value","disabled-field":"notEnabled","disabled":true},on:{"change":function($event){return _vm.onCaseTypeChange()}},model:{value:(_vm.elementData.case_type_id),callback:function ($$v) {_vm.$set(_vm.elementData, "case_type_id", $$v)},expression:"elementData.case_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),(_vm.disabled == false)?_c('b-col',{staticClass:"mr-1 ml-1",attrs:{"id":"caseDateFields"}},[_c('b-row',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.start_date')))]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.caseDate.start_date))+" ")],1),_c('b-row',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.end_date')))]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.caseDate.end_date))+" ")],1),_c('b-row',[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.reschedule()}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.reschedule')))])])],1)],1):_vm._e(),(
                _vm.elementData.is_last_page && _vm.currentConsultStatus == 'ATTENDED'
              )?_c('b-col',{staticClass:"mr-1 ml-1"},[_c('b-row',[(
                    _vm.hasRoles([
                      'CONSULTATION_COORDINATOR',
                      'SUPER_ADMINISTRATOR' ])
                  )?_c('div',{staticClass:"d-inline p-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.assign_edition()}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('actions.let_edit')))])])],1):_vm._e()])],1):_vm._e()],1)],1)],1),_c('div',{attrs:{"id":"identification_fields"}},[_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.patientResourcesElementName + '.section.patient_info')))])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"patient_document_type"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.patientResourcesElementName + '.object.documentType')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.patientResourcesElementName + '.object.documentType')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.documentTypeList,"value-field":"id","text-field":"value","disabled-field":"notEnabled","disabled":true},model:{value:(_vm.elementData.patient.document_type_id),callback:function ($$v) {_vm.$set(_vm.elementData.patient, "document_type_id", $$v)},expression:"elementData.patient.document_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"identity"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.patientResourcesElementName + '.object.identity')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.patientResourcesElementName + '.object.identity')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"identity","maxlength":"20","disabled":true},model:{value:(_vm.elementData.patient.identity),callback:function ($$v) {_vm.$set(_vm.elementData.patient, "identity", $$v)},expression:"elementData.patient.identity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"2","xl":"2"}},[_c('b-media',[_c('b-col',{staticClass:"text-center mb-1",attrs:{"cols":"12","md":"12"}},[_c('b-img',{staticClass:"border",attrs:{"src":_vm.localAvatar,"text":"","variant":"light-success","fluid":""}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"first_name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.patientResourcesElementName + '.object.first_name')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.patientResourcesElementName + '.object.first_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","maxlength":"60","disabled":true},model:{value:(_vm.elementData.patient.first_name),callback:function ($$v) {_vm.$set(_vm.elementData.patient, "first_name", $$v)},expression:"elementData.patient.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"second_name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.patientResourcesElementName + '.object.second_name')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.patientResourcesElementName + '.object.second_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"second_name","maxlength":"60","disabled":true},model:{value:(_vm.elementData.patient.second_name),callback:function ($$v) {_vm.$set(_vm.elementData.patient, "second_name", $$v)},expression:"elementData.patient.second_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"first_surname"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.patientResourcesElementName + '.object.first_surname')))]),_c('validation-provider',{attrs:{"name":_vm.$t(
                        _vm.patientResourcesElementName + '.object.first_surname'
                      )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_surname","maxlength":"60","disabled":true},model:{value:(_vm.elementData.patient.first_surname),callback:function ($$v) {_vm.$set(_vm.elementData.patient, "first_surname", $$v)},expression:"elementData.patient.first_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"second_surname"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.patientResourcesElementName + '.object.second_surname')))]),_c('validation-provider',{attrs:{"name":_vm.$t(
                        _vm.patientResourcesElementName + '.object.second_surname'
                      )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"second_surname","maxlength":"60","disabled":true},model:{value:(_vm.elementData.patient.second_surname),callback:function ($$v) {_vm.$set(_vm.elementData.patient, "second_surname", $$v)},expression:"elementData.patient.second_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3","xl":"3"}},[_c('date-picker',{attrs:{"element-required":false,"element-field":"birthdate","root-element-data":_vm.elementData.patient,"element-label":_vm.$t(_vm.patientResourcesElementName + '.object.birthdate'),"disabled":true}})],1),_c('b-col',{attrs:{"cols":"3","xl":"3"}},[_c('b-form-group',{attrs:{"label-for":"age"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.patientResourcesElementName + '.object.age')))]),_c('b-form-input',{attrs:{"id":"age","maxlength":"3","disabled":true},model:{value:(_vm.age),callback:function ($$v) {_vm.age=$$v},expression:"age"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('date-time-picker',{attrs:{"element-required":false,"element-field":"accident_date","root-element-data":_vm.elementData,"element-label":_vm.$t(_vm.consultResourcesElementName + '.object.accident_date'),"disabled":true}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"arl"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.patientResourcesElementName + '.object.arl')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.patientResourcesElementName + '.object.arl')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.arlList,"value-field":"id","text-field":"value","disabled":true,"disabled-field":"notEnabled"},model:{value:(_vm.elementData.arl_id),callback:function ($$v) {_vm.$set(_vm.elementData, "arl_id", $$v)},expression:"elementData.arl_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t( _vm.consultResourcesElementName + '.section.patient_company_information' )))])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.companyResourcesElementName + '.object.name')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.companyResourcesElementName + '.object.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","maxlength":"120","disabled":true},model:{value:(_vm.elementData.company.name),callback:function ($$v) {_vm.$set(_vm.elementData.company, "name", $$v)},expression:"elementData.company.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"headquarter"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.headquarter')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.headquarter')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"headquarter","disabled":true},model:{value:(_vm.elementData.accident_place),callback:function ($$v) {_vm.$set(_vm.elementData, "accident_place", $$v)},expression:"elementData.accident_place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"department"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.companyResourcesElementName + '.object.department')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.companyResourcesElementName + '.object.department')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.companyDepartmentList,"value-field":"id","text-field":"value","disabled-field":"notEnabled","disabled":true},on:{"change":function($event){return _vm.onCompanyDepartmentChange()}},model:{value:(_vm.elementData.company.department_id),callback:function ($$v) {_vm.$set(_vm.elementData.company, "department_id", $$v)},expression:"elementData.company.department_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"city"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.companyResourcesElementName + '.object.city')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.companyResourcesElementName + '.object.city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.companyCityList,"value-field":"id","text-field":"value","disabled-field":"notEnabled","disabled":true},model:{value:(_vm.elementData.company.city_id),callback:function ($$v) {_vm.$set(_vm.elementData.company, "city_id", $$v)},expression:"elementData.company.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t( _vm.consultResourcesElementName + '.object.patient_seniority' )))])],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-for":"patient_seniority_years"}},[_c('validation-provider',{attrs:{"name":_vm.$t(
                        _vm.consultResourcesElementName +
                          '.object.patient_seniority_years'
                      )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"patient_seniority_years","placeholder":_vm.$t(
                          _vm.consultResourcesElementName +
                            '.object.patient_seniority_years_ph'
                        ),"type":"number","formatter":_vm.yyFormat,"disabled":true},model:{value:(_vm.elementData.patient.seniority_years),callback:function ($$v) {_vm.$set(_vm.elementData.patient, "seniority_years", $$v)},expression:"elementData.patient.seniority_years"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-for":"patient_seniority_months"}},[_c('validation-provider',{attrs:{"name":_vm.$t(
                        _vm.consultResourcesElementName +
                          '.object.patient_seniority_months'
                      )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"patient_seniority_months","placeholder":_vm.$t(
                          _vm.consultResourcesElementName +
                            '.object.patient_seniority_months_ph'
                        ),"type":"number","formatter":_vm.mmFormat,"disabled":true},model:{value:(_vm.elementData.patient.seniority_months),callback:function ($$v) {_vm.$set(_vm.elementData.patient, "seniority_months", $$v)},expression:"elementData.patient.seniority_months"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-for":"patient_seniority_days"}},[_c('validation-provider',{attrs:{"name":_vm.$t(
                        _vm.consultResourcesElementName +
                          '.object.patient_seniority_days'
                      )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"patient_seniority_days","placeholder":_vm.$t(
                          _vm.consultResourcesElementName +
                            '.object.patient_seniority_days_ph'
                        ),"type":"number","formatter":_vm.ddFormat,"disabled":true},model:{value:(_vm.elementData.patient.seniority_days),callback:function ($$v) {_vm.$set(_vm.elementData.patient, "seniority_days", $$v)},expression:"elementData.patient.seniority_days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.section.arl_code')))])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"6","xl":"6"}},[_c('date-picker',{attrs:{"element-required":false,"element-field":"authorization_date","root-element-data":_vm.medical_order,"element-label":_vm.$t(
                      _vm.arlCodeResourcesElementName +
                        '.object.authorization_date'
                    ),"disabled":true}})],1),_c('b-col',{attrs:{"cols":"6","xl":"6"}},[_c('date-picker',{attrs:{"element-required":false,"element-field":"vigence_date","root-element-data":_vm.medical_order,"element-label":_vm.$t(_vm.arlCodeResourcesElementName + '.object.vigence_date'),"disabled":true}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.arlCodeResourcesElementName + '.object.arl_code')))]),_c('b-form-input',{attrs:{"id":"arl_code","disabled":true},model:{value:(_vm.medical_order.arl_code),callback:function ($$v) {_vm.$set(_vm.medical_order, "arl_code", $$v)},expression:"medical_order.arl_code"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t( _vm.arlCodeResourcesElementName + '.object.authorizated_by' )))]),_c('b-form-input',{attrs:{"id":"authorizated_by","disabled":true},model:{value:(_vm.medical_order.authorizated_by),callback:function ($$v) {_vm.$set(_vm.medical_order, "authorizated_by", $$v)},expression:"medical_order.authorizated_by"}})],1)],1)],1)],1)],1)],1)]}}])})],1),(!_vm.disabled)?_c('div',[_c('b-modal',{attrs:{"id":"modal-date","size":"xl","no-close-on-esc":"","no-close-on-backdrop":"","no-modal-header-close":"","hide-footer":"","title":_vm.$t(_vm.stepResourcesElementName + '.object.edit_calendar_title')},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
                    var close = ref.close;
return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.close-confirmation",modifiers:{"close-confirmation":true}}],attrs:{"size":"sm","variant":"outline-danger"}},[_vm._v(" Cancelar ")]),_c('h5',[_vm._v(" "+_vm._s(_vm.$t(_vm.stepResourcesElementName + '.object.edit_calendar_title'))+" ")])]}}],null,false,1867912644)},[[_c('calendar-edit-tab',{staticClass:"mt-2 pt-75",attrs:{"element-data":_vm.caseDate}})]],2),_c('b-modal',{attrs:{"id":"close-confirmation","size":"sm","title":"Confirmación","hide-footer":""}},[[_c('p',{staticClass:"my-1"},[_vm._v("Si cierra el diálogo perderá los cambios")]),_c('p',[_vm._v("¿Desea continuar?")]),_c('b-button',{attrs:{"size":"mb-1 mb-sm-0 mr-0 mr-sm-1","variant":"danger"},on:{"click":function($event){return _vm.reset_changes()}}},[_vm._v(" Aceptar ")]),_c('b-button',{attrs:{"size":"mb-1 mb-sm-0 mr-0 mr-sm-1","variant":"primary"},on:{"click":function($event){return _vm.close_confirmation()}}},[_vm._v(" Cancelar ")])]],2)],1):_vm._e(),_c('div',[_c('b-modal',{attrs:{"id":'modal-assign-edition-user-' + _vm.caseDateUuid,"size":"xl","hide-footer":"","title":"Permiso de edición"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
                    var close = ref.close;
return [_c('h5',[_vm._v("Permiso de Edición")])]}}])},[[_c('assign-edition-tab',{staticClass:"mt-2 pt-75",attrs:{"element-data":_vm.elementData,"case-date-uuid":_vm.caseDateUuid,"doctor-list":_vm.doctorList,"edition-time":_vm.editionTime}})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }