<template>
  <div>

    <b-form>
      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName + '.section.certifications') }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <radio-select-picker
                  :element-required="!disabled"
                  element-field="requires_certifications_id"
                  :root-element-data="elementData"
                  :element-label="$t(consultResourcesElementName + '.object.requires_certifications')"
                  :element-selection-list="booleanList"
                  :inline="true"
                  :exec="update_visibility"
                  :disabled="disabled"
                />
              </b-col>
            </b-row>
            <b-row v-if="false">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.consult_date') }}</span>
                  {{ formatDate(elementData.final_consult_date) }}
            </b-row>
            <div
              v-if="certificationRequired"
              id="certification_fields"
            >
              <b-row>
                <b-col cols="3">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.certification_type') }}</span><span v-if="!disabled" class="text-danger">*</span>
                </b-col>
                <b-col cols="4">
                  <v-select
                    v-model="elementData.certification_type_id"
                    :options="certificationTypeList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    :disabled="disabled"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <radio-select-picker
                    :element-required="true"
                    element-field="extension_id"
                    :root-element-data="elementData"
                    :element-label="$t(consultResourcesElementName + '.object.extension')"
                    :element-selection-list="booleanList"
                    :inline="true"
                    :disabled="disabled"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <radio-select-picker
                    :element-required="true"
                    element-field="origin_classification_id"
                    :root-element-data="elementData"
                    :element-label="$t(consultResourcesElementName + '.object.originClassificationList')"
                    :element-selection-list="localOriginClassificationList"
                    :inline="true"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col
                  cols="4"
                  md="4"
                >
                  <date-picker
                    :element-required="!disabled"
                    element-field="from_date"
                    :root-element-data="elementData"
                    :element-label="$t(consultResourcesElementName + '.object.certification_from_date')"
                    :disabled="disabled"
                  />
                </b-col>
                <b-col
                  cols="4"
                  md="4"
                >
                  <date-picker
                    :element-required="!disabled"
                    element-field="to_date"
                    :root-element-data="elementData"
                    :element-label="$t(consultResourcesElementName + '.object.certification_to_date')"
                    :disabled="disabled"
                  />
                </b-col>
                <b-col
                  cols="4"
                  md="4"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.quantity_certification_days') }}</span>
                  {{ quantity_days }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-for="certification_observations"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.certification_observations') }}</span><span v-if="!disabled" class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t(consultResourcesElementName + '.object.certification_observations')"
                      :rules="disabled ? '' : 'required'"
                    >
                      <b-form-textarea
                        id="certification_observations"
                        v-model="elementData.certification_observations"
                        maxlength="6000"
                        max="6000"
                        :disabled="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </validation-observer>
    </b-form>
  </div>
</template>
<script>
import {
  BFormTextarea, BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BFormTextarea, RadioSelectPicker, DatePicker, BCard, BRow, BCol, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    certificationTypeList: {
      type: Array,
      required: true,
    },
    booleanList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    originClassificationList: {
      type: Array,
      required: true,
    },
    elementDataFull: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      certificationRequired: false,
      localOriginClassificationList: [],
    }
  },
  computed: {
    quantity_days: {
      get() {
        if (this.elementData.from_date && this.elementData.to_date) {
          this.elementData.medical_disabily_days = moment.duration(moment(this.elementData.to_date).diff(moment(this.elementData.from_date))).asDays() + 1
          return this.elementData.medical_disabily_days
        }
        return ''
      },
    },
  },
  watch: {
  },
  mounted() {
    this.update_visibility()
    this.elementData.origin_classification_id = this.elementDataFull.origin_classification_id
    this.localOriginClassificationList = this.originClassificationList.filter(element => element.id > 0)
  },
  methods: {
    update_visibility() {
      const data = this.booleanList.find(element => this.elementData.requires_certifications_id === element.id)
      if (data && data.key === 'YES') {
        this.certificationRequired = true
      } else {
        this.certificationRequired = false
      }
    },
    formatDate(element) {
      
      let ret = moment(element, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm')
      if (ret == 'Invalid date' || ret == 'Fecha inválida') {
        ret = moment(element).format('DD/MM/YYYY HH:mm')
      }
      return ret == 'Invalid date' || ret == 'Fecha inválida' ? '' : ret
    },
  },
}
</script>
<style>
.left_title {
    text-decoration: underline;
    font-weight: bolder !important;
}

.hidden-div{
    display: none;
  }
.info_title {
    text-decoration: underline;
    font-weight: bolder !important;
}
</style>
