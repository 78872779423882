<template>
  <b-form @submit.prevent="save">

    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName + '.section.consult') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              xl="12"
            >
              <b-form-group
                label-for="consult_name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(consultResourcesElementName + '.object.consult_name') }}</span>

                <b-form-input
                  id="consult_name"
                  v-model="consultData.consult_name"
                  :placeholder="$t(consultResourcesElementName + '.object.consult_name')"
                  maxlength="200"
                  max="200"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="consult_observation"
              >

                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(consultResourcesElementName + '.object.consult_observation') }}</span><span class="text-danger">*</span>

                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.consult_observation')"
                  rules="required"
                >
                  <b-form-textarea
                    id="consult_observation"
                    v-model="consultData.consult_observation"
                    maxlength="500"
                    max="500"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-button
              id="cancel-button"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              @click="hide_modal()"
            >
              {{ $t('actions.back') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              {{ $t('actions.save') }}
            </b-button>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>
<script>
import {
  BFormTextarea, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormTextarea, BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver,
  },
  props: {
    consultData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    hide_modal() {
      this.$root.$emit('bv::hide::modal', 'modal-consult')
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          _self.$root.$emit('save-consult', _self.consultData)
          _self.$root.$emit('bv::hide::modal', 'modal-consult')
        }
      })
    },
  },
}
</script>
<style>
.left_title {
    text-decoration: underline;
    font-weight: bolder !important;
}

.hidden-div{
    display: none;
  }
</style>
