<template>
  <div>
    <b-row
      v-if="showheader"
      class="m-1"
    >
      <b-col
        cols="6"
        class="d-flex"
      >
        <h2 class="mb-1 text-primary">
          - {{ title }}
        </h2>
      </b-col>
    </b-row>

    <b-table
      ref="refElementsListTable"
      class="position-relative"
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')"
    >
      <!-- Column: key -->
      <template
        v-if="type == 'FOLLOWUP'"
        #cell(next_consult)="data"
      >
        {{ getNextConsult(data.item) }}
      </template>
      <template #cell(name)="data">
        {{ data.item.name }}
      </template>
      <template #cell(date)="data">
        {{ formatDate(data.item.date) }}
      </template>
      <template #cell(result)="data">
        {{ data.item.result ? data.item.result.value : '' }}
      </template>
      <template #cell(provider)="data">
        {{ data.item.provider ? data.item.provider.name : '' }}
      </template>
      <template #cell(made_by_avenir)="data">
        <span v-if="data.item.made_by_avenir"> {{ $t('boolean.yes') }}</span>
        <span v-else> {{ $t('boolean.no') }} </span>
      </template>
      <template #cell(report)="data">
        <div v-if="data.item.report">
          <a
            id="view-report-button"
            class="btn mb-1 mb-sm-0 mr-0 mr-sm-1 btn-secondary"
            :href="getUrl(data.item.report)"
            target="_blank"
          >
            {{ $t(RESOURCES_ELEMENT_NAME + ".download") }}
          </a>
          <b-tooltip
            target="view-report-button"
            triggers="hover"
          >
            <span class="align-middle ml-50">{{ $t(RESOURCES_ELEMENT_NAME + ".download") }}</span>
          </b-tooltip>
        </div>
      </template>

    </b-table>
    <!-- Pagination -->
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="total"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BPagination, BTooltip,
} from 'bootstrap-vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from '@/views/case/labtests/storeModule'

export default {

  components: {
    BTooltip,
    BRow,
    BCol,
    BTable,
    BPagination,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    showheader: {
      type: Boolean,
      default: true,
    },
    showheader: {
      type: Boolean,
      default: true,
    },
    followtype: {
      type: String,
      default: undefined,
    },
    case_id: {
      type: Number,
      requiered: true,
    },
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      query: '',
      selected_element: {},
      currentSelection: {},
      patient_id: undefined,
      patient_identity_type: '',
      patient_identity: '',
      patient_name: '',
      tableColumns: [],
      case: undefined,
    }
  },
  watch: {
    perPage(val) {
      this.fetchElements(this.type)
    },
    currentPage(val) {
      this.fetchElements(this.type)
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements(this.type)
    },
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'labtests'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-mh-v-${this.RESOURCES_ELEMENT_NAME}`
    this.patient_id = router.currentRoute.params.patient_id

    this.tableColumns = [
      { key: 'name', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.name`), sortable: true },
      { key: 'date', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.date`), sortable: true },
      { key: 'result', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.result`), sortable: true },
      { key: 'provider', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.provider`), sortable: true },
      { key: 'made_by_avenir', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.made_by_avenir`), sortable: true },
      { key: 'report', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.report`), sortable: true },
    ]

    if (this.type == 'FOLLOWUP') {
      this.tableColumns.splice(0, 0, { key: 'auth_number', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.auth_number`), sortable: true })
    }

    if (this.type == 'FOLLOWUP') {
      this.tableColumns.splice(0, 0, { key: 'next_consult', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.follow_type`), sortable: true })
    }

    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    this.fetchElements(this.type)
    this.fetch_labs()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    getNextConsult(item) {
      if (item.order && item.order.next_consult) {
        return item.order.next_consult.value
      }
      if (item.next_consult) {
        return item.next_consult.value
      }
      return ''
    },
    getUrl(item) {
      if (!item || !item.path) {
        return ''
      }
      let path = item.path.split('/')
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
    },
    formatDate(field) {
      return field ? moment(field, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
    },
    async fetch_labs() {
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_labs`, { }).then(response => {
        const list = response.data.items
        list.unshift({ id: null, name: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.select_lab_default`) })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    fetchElements(type) {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_lab_tests`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            type,
            case: this.case_id,
            follow_type: this.followtype,
          },
        })
        .then(response => {
          this.items = response.data.items
          this.total = response.data.total
        })
        .catch(error => {
          console.info(error)
        })
    },
    getUrl(item) {
      if (!item || !item.path) {
        return ''
      }
      let path = item.path.split(/[\/,\\]+/)
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
    },
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
