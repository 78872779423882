<template>
  <div>
    <b-form @submit.prevent="save">
      <validation-observer
        ref="elementForm"
        #default="{ invalid }">
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="d-none d-sm-inline">{{
                $t(consultResourcesElementName + '.section.consult')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="add_consult()">
                {{ $t('actions.add') }}
              </b-button>
            </b-row>
            <b-row>
              <b-table
                class="position-relative"
                :items="orderData.services"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                :empty-text="
                  $t(consultResourcesElementName + '.search.not_found')
                ">
                <!-- Column: actions -->
                <template #cell(actions)="data">
                  <b-col>
                    <b-button
                      :id="get_delete_button_id(data.item.id)"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      variant="primary"
                      @click="delete_consult(data.item.id)">
                      <feather-icon icon="DeleteIcon" />
                    </b-button>
                    <b-button
                      :id="get_edit_button_id(data.item.id)"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      variant="primary"
                      @click="edit_consult(data.item)">
                      <feather-icon icon="EditIcon" />
                    </b-button>

                    <b-tooltip
                      :target="get_edit_button_id(data.item)"
                      triggers="hover">
                      <span class="align-middle ml-50">{{
                        $t('actions.edit')
                      }}</span>
                    </b-tooltip>
                    <b-tooltip
                      :target="get_delete_button_id(data.item.id)"
                      triggers="hover">
                      <span class="align-middle ml-50">{{
                        $t('actions.delete')
                      }}</span>
                    </b-tooltip>
                  </b-col>
                </template>
              </b-table>
            </b-row>
            <b-row>
              <b-button
                id="cancel-button"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="primary"
                @click="hide_modal()">
                {{ $t('actions.back') }}
              </b-button>
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="invalid">
                {{ $t('actions.save') }}
              </b-button>
            </b-row>
          </b-card-body>
        </b-card>
      </validation-observer>
    </b-form>
    <div>
      <b-modal
        id="modal-consult"
        size="lg"
        hide-footer
        :title="$t(consultResourcesElementName + '.object.consult')">
        <template>
          <consult
            ref="modal_consult"
            :consult-data="consultData" />
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BModal,
  BTooltip,
  BTable,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BForm,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import Consult from './Consult.vue'

export default {
  components: {
    BModal,
    BTooltip,
    Consult,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BForm,
    ValidationObserver,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      tableColumns: [],
      consultData: {},
    }
  },
  watch: {},
  beforeDestroy() {
    this.$root.$off('save-consult')
  },
  mounted() {
    this.tableColumns = [
      {
        key: 'consult_name',
        label: this.$t(
          `${this.consultResourcesElementName}.object.consult_name`
        ),
        sortable: true,
      },
      {
        key: 'consult_observation',
        label: this.$t(
          `${this.consultResourcesElementName}.object.consult_observation`
        ),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.consultResourcesElementName}.object.actions`),
      },
    ]
    const _self = this
    this.$root.$on('save-consult', (consultData) => {
      const index = _self.orderData.services.findIndex(
        (element) => element.id == consultData.id
      )
      if (index < 0) {
        _self.orderData.services.push(consultData)
      }
    })
  },
  methods: {
    get_edit_button_id(key) {
      return `edit_consult_button${key}`
    },
    get_delete_button_id(key) {
      return `delete_consult_button${key}`
    },
    hide_modal() {
      this.$root.$emit('bv::hide::modal', 'modal-consults')
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          _self.$root.$emit('save-order', _self.orderData)
          _self.$root.$emit('bv::hide::modal', 'modal-consults')
        }
      })
    },
    add_consult() {
      this.consultData = { id: Math.floor(Math.random() * 100) * -1, status: 1 }
      this.$root.$emit('bv::show::modal', 'modal-consult')
    },
    edit_consult(item) {
      this.consultData = item
      this.$root.$emit('bv::show::modal', 'modal-consult')
    },
    delete_consult(id) {
      this.orderData.services.splice(
        this.orderData.services.findIndex((element) => element.id == id),
        1
      )
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
