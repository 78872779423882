var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.section.clinical_laboratory')))])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('radio-select-picker',{attrs:{"element-required":true,"element-field":"request_destination_id","root-element-data":_vm.orderData,"element-label":_vm.$t(
                  _vm.consultResourcesElementName + '.object.request_destination'
                ),"element-selection-list":_vm.internalDestinationList,"inline":true,"exec":_vm.update_request_destination}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"element-status"}},[_c('b-form-checkbox',{staticClass:"mr-0 mt-50",attrs:{"name":"is-rtl","inline":"","value":"1","unchecked-value":"0"},model:{value:(_vm.orderData.requires_freezer),callback:function ($$v) {_vm.$set(_vm.orderData, "requires_freezer", $$v)},expression:"orderData.requires_freezer"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.requires_freezer')))])],1)],1)],1)],1)],1),_c('b-card',[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('date-picker',{attrs:{"element-required":false,"element-field":"proposed_date","root-element-data":_vm.orderData,"element-label":_vm.$t(_vm.consultResourcesElementName + '.object.proposed_date')}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"city"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t( _vm.consultResourcesElementName + '.object.clinical_laboratory_lab' )))]),_c('validation-provider',{attrs:{"name":_vm.$t(
                    _vm.consultResourcesElementName +
                      '.object.clinical_laboratory_lab'
                  )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.localClinicalLaboratoryList,"label":"name","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.onLaboratoryChange},model:{value:(_vm.orderData.clinical_laboratory_lab_id),callback:function ($$v) {_vm.$set(_vm.orderData, "clinical_laboratory_lab_id", $$v)},expression:"orderData.clinical_laboratory_lab_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"clinical_department"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t( _vm.consultResourcesElementName + '.object.clinical_department' )))]),_c('validation-provider',{attrs:{"name":_vm.$t(
                    _vm.consultResourcesElementName +
                      '.object.clinical_department'
                  )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.clinicalLaboratoryDepartmentList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.onDepartmentChange},model:{value:(_vm.orderData.clinical_department_id),callback:function ($$v) {_vm.$set(_vm.orderData, "clinical_department_id", $$v)},expression:"orderData.clinical_department_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"city"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.clinical_city')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.clinical_city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.clinicalLaboratoryCityList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.onCityChange},model:{value:(_vm.orderData.clinical_city_id),callback:function ($$v) {_vm.$set(_vm.orderData, "clinical_city_id", $$v)},expression:"orderData.clinical_city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"clinical_laboratory_headquarter"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t( _vm.consultResourcesElementName + '.object.clinical_laboratory_headquarter' )))]),(_vm.requiredHeadquarter)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t(
                    _vm.consultResourcesElementName +
                      '.object.clinical_laboratory_headquarter'
                  ),"rules":{
                  required: _vm.requiredHeadquarter,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.clinicalHeadquarterList,"label":"name","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.onHeadquarterChange},model:{value:(_vm.orderData.clinical_laboratory_headquarter_id),callback:function ($$v) {_vm.$set(_vm.orderData, "clinical_laboratory_headquarter_id", $$v)},expression:"orderData.clinical_laboratory_headquarter_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label-for":"clinical_laboratory_headquarter_address"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t( _vm.consultResourcesElementName + '.object.clinical_laboratory_headquarter_address' )))]),_c('b-form-input',{attrs:{"id":"clinical_laboratory_headquarter_address","placeholder":_vm.$t(
                    _vm.consultResourcesElementName +
                      '.object.clinical_laboratory_headquarter_address'
                  ),"disabled":true},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"9","md":"9"}},[(
                _vm.clinicalLaboratoryServicesList &&
                _vm.clinicalLaboratoryServicesList.length
              )?_c('filtered-multi-select',{attrs:{"root-element-data":_vm.orderData,"element-label":_vm.$t(_vm.consultResourcesElementName + '.object.clinical_services'),"element-field":"selected_services","available-list":_vm.clinicalLaboratoryServicesList}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"3","md":"3"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"id":"add-button","variant":"primary"},on:{"click":function($event){return _vm.add_services()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.add_service'))+" ")])],1)],1),_c('b-row',[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.orderData.services,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t(_vm.consultResourcesElementName + '.search.not_found')},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-col',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"id":_vm.get_delete_button_id(data.item.id),"variant":"primary"},on:{"click":function($event){return _vm.delete_service(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"DeleteIcon"}})],1),_c('b-tooltip',{attrs:{"target":_vm.get_delete_button_id(data.item.id),"triggers":"hover"}},[_c('span',{staticClass:"align-middle ml-50"},[_vm._v(_vm._s(_vm.$t('actions.delete')))])])],1)]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"service_observations"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t( _vm.consultResourcesElementName + '.object.service_observations' )))]),_c('validation-provider',{attrs:{"name":_vm.$t(
                    _vm.consultResourcesElementName +
                      '.object.service_observations'
                  )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"observations","maxlength":"250","max":"250"},model:{value:(_vm.orderData.observations),callback:function ($$v) {_vm.$set(_vm.orderData, "observations", $$v)},expression:"orderData.observations"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-row',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"id":"cancel-button","variant":"primary"},on:{"click":function($event){return _vm.hide_modal()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }