<template>
    <div>
        <b-form-group :label-for="elementLabel">
            <div v-if="elementLabel">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold info_title">{{elementLabel}}</span><span v-if="elementRequired" class="text-danger">*</span>
            </div>
            
            <validation-provider
                    #default="{ errors }"
                    :name="elementLabel"
                    :rules="rules"
                    >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    autocomplete="off"
                >
                    <flat-pickr
                        :id="elementField"
                        v-model="elementData"
                        class="form-control"
                        placeholder="DD/MM/AAAA"
                        :config="config"
                        autocomplete="off"
                        :disabled="disabled"
                    />
                    <b-input-group-append>
                        <b-button
                            variant="primary"
                            class="mb-0 mb-sm-0 mr-0 mr-sm-1"
                            data-toggle
                            :disabled="disabled"
                        >
                            <feather-icon icon="CalendarIcon" />
                        </b-button>
                        <div v-if="elementLabel || elementClearable">
                            <b-button
                                variant="primary"
                                class="mb-0 mb-sm-0 mr-0 mr-sm-0"
                                data-clear
                                :disabled="disabled"
                            >
                                <feather-icon icon="XIcon" />
                            </b-button>
                        </div>
                    </b-input-group-append>
                </b-input-group>
                <br>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
    </div>
</template>

<script>
import {
    BInputGroup, BInputGroupAppend, BFormGroup, BFormDatepicker, BFormInput, BForm, BButton
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'

export default {
    components: {
        BButton, BInputGroup, BForm, BInputGroupAppend, BFormGroup, BFormDatepicker, BFormInput, ValidationProvider, flatPickr
    },
    props: {
        rootElementData: {
            type: Object,
            required: true,
        },
        elementLabel: {
            type: String,
            required: false,
        },
        elementClearable: {
            type: Boolean,
            required: false,
            default: false
        },
        elementField: {
            type: String,
            required: true,
        },
        elementRequired: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            elementLabelInput: '',
            config: {
                static: true,
                wrap: true, // set wrap to true only when using 'input-group'
                dateFormat: 'd/m/Y h:i K',
                locale: Spanish, // locale for this instance only         
                allowInput: true,
                enableTime: true,
                time_24hr: false,
                defaultDate : null
            }
        }
    },
    computed: {
        rules: {
            get() {
                return this.elementRequired ? "required" : undefined
            }
        },
        elementData: {
            get() {
              const fieldValue = this.rootElementData[this.elementField];
              if (fieldValue && moment(fieldValue, 'DD/MM/YYYY HH:mm', true).isValid()) {
                return moment(fieldValue).format('DD/MM/YYYY HH:mm');
              }
              return fieldValue;
            },
            set(newValue) {
              if (newValue && moment(newValue, 'DD/MM/YYYY HH:mm', true).isValid()) {
                this.rootElementData[this.elementField] = moment(newValue, 'DD/MM/YYYY HH:mm').toDate();
              } else {
                this.rootElementData[this.elementField] = newValue;
              }
            }
        }
    },
    mounted() {
        this.elementLabelInput = this.elementLabel + '-input'
    },
    watch: {
        rootElementData(newValue, oldValue) {
            const fieldValue = this.rootElementData[this.elementField];
            if (fieldValue && moment(fieldValue, 'DD/MM/YYYY HH:mm', true).isValid()) {
            return moment(fieldValue).format('DD/MM/YYYY HH:mm');
            }
            return fieldValue;
        },
    },
}
</script>