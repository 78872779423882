<template>
  <b-form @submit.prevent="save">

    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName + '.section.medicine') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="cum_file"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(medicineResourcesElementName + '.object.cum_file') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(medicineResourcesElementName + '.object.cum_file')"
                  rules="required"
                >
                  <v-select
                    v-model="medicineData.selected_id"
                    :options="medicinesList"
                    label="cups"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onMedicineChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="cum_file"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(medicineResourcesElementName + '.object.cum_name') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(medicineResourcesElementName + '.object.cum_file')"
                  rules="required"
                >
                  <v-select
                    v-model="medicineData.selected_id"
                    :options="medicinesList"
                    label="cups_name"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onMedicineChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="quantity"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(medicineResourcesElementName + '.object.quantity') }}</span>

                <b-form-input
                  id="dosage"
                  v-model="medicineData.dosage"
                  :placeholder="$t(medicineResourcesElementName + '.object.quantity')"
                  type="number"
                  :formatter="numberFormat"
                  @change="updateNumberToWord()"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="quantity_word"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(medicineResourcesElementName + '.object.quantity_word') }}</span>

                <b-form-input
                  id="quantity_word"
                  :placeholder="$t(medicineResourcesElementName + '.object.quantity_word')"
                  :value="quantity_word"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="concentration"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(medicineResourcesElementName + '.object.concentration') }}</span>

                <b-form-input
                  id="concentration"
                  v-model="medicineData.quantity + ' ' + medicineData.units_of_measure.value"
                  :placeholder="$t(medicineResourcesElementName + '.object.concentration')"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="dosage_form"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(medicineResourcesElementName + '.object.dosage_form') }}</span>

                <b-form-input
                  id="dosage_form"
                  v-model="medicineData.dosage_form.value"
                  :placeholder="$t(medicineResourcesElementName + '.object.dosage_form')"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="administration_route"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(medicineResourcesElementName + '.object.administration_route') }}</span>

                <b-form-input
                  id="administration_route"
                  v-model="medicineData.administration_route.value"
                  :placeholder="$t(medicineResourcesElementName + '.object.administration_route')"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="dosage"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(medicineResourcesElementName + '.object.dosage') }}</span>

                <b-form-input
                  id="dosage"
                  v-model="medicineData.frequency"
                  :placeholder="$t(medicineResourcesElementName + '.object.dosage')"
                  type="number"
                  :formatter="numberFormat"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="8"
              xl="8"
            >
              <b-form-group
                label-for="treatment_time"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(medicineResourcesElementName + '.object.treatment_time') }}</span>

                <b-form-input
                  id="treatment_time"
                  v-model="medicineData.treatment_time"
                  :placeholder="$t(medicineResourcesElementName + '.object.treatment_time')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-button
              id="cancel-button"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              @click="hide_modal()"
            >
              {{ $t('actions.back') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              {{ $t('actions.save') }}
            </b-button>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { numeroALetras } from '@/libs/numeroALetras'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, vSelect,
  },
  props: {
    medicineData: {
      type: Object,
      required: true,
    },
    medicinesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      medicineResourcesElementName: 'services',
      quantity_word: '',
    }
  },
  mounted() {
    this.updateNumberToWord()
  },
  created() {
    if (!this.medicineData.quantity) {
      this.medicineData.quantity = ''
    }
    if (!this.medicineData.units_of_measure) {
      this.medicineData.units_of_measure = { value: '' }
    }
  },
  methods: {
    updateNumberToWord() {
      if (!this.medicineData.dosage) {
        this.quantity_word = ''
      } else {
        this.quantity_word = numeroALetras(this.medicineData.dosage)
      }
    },
    numberFormat(value) {
      const ret = parseInt(value).toLocaleString('en-US', {
        useGrouping: false,
      })
      return ret > 9999999999 ? 9999999999 : ret
    },
    onMedicineChange() {
      // this.medicineData = this.medicinesList.find(element => element.id == this.medicineData.selected_id)
      Object.assign(this.medicineData, this.medicinesList.find(element => element.id == this.medicineData.selected_id))
      if (!this.medicineData.quantity) {
        this.medicineData.quantity = ''
      }
      if (!this.medicineData.units_of_measure) {
        this.medicineData.units_of_measure = { value: '' }
      }
      if (!this.medicineData.dosage_form) {
        this.medicineData.dosage_form = { value: '' }
      }
      this.updateNumberToWord()
    },
    hide_modal() {
      this.$root.$emit('bv::hide::modal', 'modal-medicine')
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          _self.$root.$emit('save-medicine', _self.medicineData)
          _self.$root.$emit('bv::hide::modal', 'modal-medicine')
        }
      })
    },
  },
}
</script>
<style>
.left_title {
    text-decoration: underline;
    font-weight: bolder !important;
}

.hidden-div{
    display: none;
  }
</style>
