<template>
  <div v-if="followType">
    <b-form>
      <validation-observer
        ref="elementForm"
        #default="{ invalid }">
        <b-card>
          <b-card-header v-if="elementData.consult_name">
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="d-none d-sm-inline"
                >{{ $t(consultResourcesElementName + '.section.orders') }}
                {{ elementData.consult_name }}</span
              >
            </div>
          </b-card-header>
          <b-card-header v-else>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="d-none d-sm-inline"
                >{{ $t(consultResourcesElementName + '.section.orders') }}
                {{ followType.value }}</span
              >
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="4">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.main_diagnosis')
                }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <v-select
                  v-model="elementData.main_diagnosis_id"
                  :options="diagnosisList"
                  label="key"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :disabled="disabled" />
              </b-col>
              <b-col cols="8">
                <v-select
                  v-model="elementData.main_diagnosis_id"
                  :options="diagnosisList"
                  label="value"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :disabled="disabled" />
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="4">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(
                    consultResourcesElementName + '.object.secondary_diagnosis'
                  )
                }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <v-select
                  v-model="elementData.secondary_diagnosis_id"
                  :options="diagnosisList"
                  label="key"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :disabled="disabled" />
              </b-col>
              <b-col cols="8">
                <v-select
                  v-model="elementData.secondary_diagnosis_id"
                  :options="diagnosisList"
                  label="value"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :disabled="disabled" />
              </b-col>
            </b-row>
            <b-row class="mb-1 mt-1">
              <b-col cols="2">
                <b-button
                  variant="primary"
                  class="mb-1 mt-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="disabled"
                  @click="addMedicalConsult()">
                  {{ $t('actions.add_medical_consult') }}
                </b-button>
              </b-col>
              <b-col cols="3">
                <b-button
                  variant="primary"
                  class="mb-1 mt-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="disabled"
                  @click="addMedicalLaboratory()">
                  {{ $t('actions.add_medical_laboratory') }}
                </b-button>
              </b-col>
              <b-col cols="3">
                <b-button
                  variant="primary"
                  class="mb-1 mt-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="disabled"
                  @click="addMedicine()">
                  {{ $t('actions.add_medicine') }}
                </b-button>
              </b-col>
              <b-col cols="2">
                <b-button
                  variant="primary"
                  class="mb-1 mt-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="disabled"
                  @click="addVaccine()">
                  {{ $t('actions.add_vaccine') }}
                </b-button>
              </b-col>
              <b-col cols="2">
                <b-button
                  variant="primary"
                  class="mb-1 mt-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="disabled"
                  @click="addConsult()">
                  {{ $t('actions.add_consult') }}
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-table
                class="position-relative"
                :items="internal_orders"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                :empty-text="
                  $t(consultResourcesElementName + '.search.not_found')
                ">
                <!-- Column: actions -->
                <template #cell(actions)="data">
                  <b-col>
                    <b-button
                      v-if="data.item.status"
                      :id="get_edit_button_id(data.item.id)"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      variant="primary"
                      :disabled="
                        data.item.isNew !== true &&
                        data.item.vaccineNew !== true
                      "
                      @click="edit_order(data.item)">
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      v-if="data.item.status"
                      :id="get_delete_button_id(data.item.id)"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      variant="primary"
                      :disabled="
                        data.item.isNew !== true &&
                        data.item.vaccineNew !== true
                      "
                      @click="delete_order(data.item)">
                      <feather-icon icon="DeleteIcon" />
                    </b-button>

                    <b-tooltip
                      v-if="data.item.status"
                      :target="get_edit_button_id(data.item.id)"
                      triggers="hover">
                      <span class="align-middle ml-50">{{
                        $t('actions.edit')
                      }}</span>
                    </b-tooltip>
                    <b-tooltip
                      v-if="data.item.status"
                      :target="get_delete_button_id(data.item.id)"
                      triggers="hover">
                      <span class="align-middle ml-50">{{
                        $t('actions.delete')
                      }}</span>
                    </b-tooltip>
                  </b-col>
                </template>
              </b-table>
            </b-row>
          </b-card-body>
        </b-card>
      </validation-observer>
    </b-form>
    <div v-if="!disabled">
      <b-modal
        id="modal-medical-consult"
        size="lg"
        hide-footer
        title="Orden médica de consulta">
        <template>
          <medical-consult
            :finished-consults="finishedConsults"
            :follow-type="followType.key"
            :consult-data="orderData"
            :case-tracking-type="caseTrackingType"
            :medical-consult-services-list="medicalConsultServicesList"
            :follow-date="followDate"
            :follow-date2="followDate2"
            :follow-date3="followDate3"
            :follow-date4="followDate4"
            :requires-follow="requiresFollow" />
        </template>
      </b-modal>
    </div>
    <div v-if="!disabled">
      <b-modal
        id="modal-clinical-laboratory"
        size="lg"
        hide-footer
        :title="
          $t(consultResourcesElementName + '.object.clinical_laboratory')
        ">
        <template>
          <clinical-laboratory
            v-if="clinicalLaboratoryServicesList"
            :order-data="orderData"
            :destination-orders-list="destinationOrdersList"
            :clinical-laboratory-city-list="clinicalLaboratoryCityList"
            :clinical-laboratory-department-list="
              clinicalLaboratoryDepartmentList
            "
            :clinical-headquarter-list="clinicalHeadquarterList"
            :clinical-laboratory-list="clinicalLaboratoryList"
            :clinical-laboratory-services-list="clinicalLaboratoryServicesList"
            :follow-type="followType.key"
            :follow-date="followDate"
            :follow-date2="followDate2"
            :follow-date3="followDate3"
            :follow-date4="followDate4"
            :required-headquarter="requiredHeadquarter" />
        </template>
      </b-modal>
    </div>
    <div v-if="!disabled">
      <b-modal
        id="modal-medicines"
        size="lg"
        hide-footer
        :title="$t(consultResourcesElementName + '.object.medicine')">
        <template>
          <medicines
            v-if="medicinesList"
            :element-data="elementData"
            :order-data="orderData"
            :destination-orders-list="destinationOrdersList"
            :medicines-list="medicinesList" />
        </template>
      </b-modal>
    </div>
    <div v-if="!disabled">
      <b-modal
        id="modal-vaccines"
        size="lg"
        hide-footer
        :title="$t(consultResourcesElementName + '.object.vaccine')">
        <template>
          <vaccines
            v-if="vaccineServicesList"
            :order-data="orderData"
            :destination-orders-list="destinationOrdersList"
            :vaccine-city-list="vaccineCityList"
            :vaccine-department-list="vaccineDepartmentList"
            :vaccine-headquarter-list="vaccineHeadquarterList"
            :vaccine-laboratory-list="vaccineLaboratoryList"
            :vaccine-services-list="vaccineServicesList"
            :required-vaccine-headquarter="requiredVaccineHeadquarter" />
        </template>
      </b-modal>
    </div>
    <div v-if="!disabled">
      <b-modal
        id="modal-consults"
        size="lg"
        hide-footer
        :title="$t(consultResourcesElementName + '.object.consult')">
        <template>
          <consults :order-data="orderData" />
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BModal,
  BTable,
  BTooltip,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BForm,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import ClinicalLaboratory from './orders/ClinicalLaboratory.vue'
import Medicines from './orders/Medicines.vue'
import Vaccines from '../utils/orders/Vaccines.vue'
import Consults from './orders/Consults.vue'
import MedicalConsult from './orders/MedicalConsult.vue'
import '@core/scss/vue/libs/vue-select.scss'
import moment from 'moment'

export default {
  components: {
    MedicalConsult,
    Consults,
    Vaccines,
    Medicines,
    BModal,
    ClinicalLaboratory,
    BTable,
    BTooltip,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BForm,
    ValidationObserver,
    vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    diagnosisList: {
      type: Array,
      required: true,
    },
    destinationOrdersList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryDepartmentList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryCityList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryList: {
      type: Array,
      required: true,
    },
    clinicalHeadquarterList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryServicesList: {
      type: Array,
      required: true,
    },
    medicinesList: {
      type: Array,
      required: true,
    },
    vaccineDepartmentList: {
      type: Array,
      required: true,
    },
    vaccineCityList: {
      type: Array,
      required: true,
    },
    vaccineLaboratoryList: {
      type: Array,
      required: true,
    },
    vaccineHeadquarterList: {
      type: Array,
      required: true,
    },
    vaccineServicesList: {
      type: Array,
      required: true,
    },
    serviceTypeList: {
      type: Array,
      required: true,
    },
    caseTrackingType: {
      type: Array,
      required: true,
    },
    medicalConsultServicesList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    caseType: {
      type: String,
      required: true,
    },
    booleanList: {
      type: Array,
      required: true,
    },
    requiredHeadquarter: {
      type: Boolean,
      required: true,
    },
    requiredVaccineHeadquarter: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      orderData: {},
      tableColumns: [],
      all_internal_orders: [],
      finishedConsults: undefined,
      followType: undefined,
      followDate: '',
      followDate2: '',
      followDate3: '',
      followDate4: '',
      proposed_date: '',
      requiresFollow: false,
    }
  },
  computed: {
    internal_orders() {
      return this.all_internal_orders.filter((element) => element.status == 1)
    },
  },
  watch: {},
  beforeDestroy() {
    this.$root.$off('save-order')
  },
  mounted() {
    const _self = this
    _self.all_internal_orders = [..._self.elementData.orders]
    _self.proposedDate(_self.elementData)
    this.followDate = this.proposed_date

    this.finishedConsults = this.elementData?.process?.steps
      ?.sort((a, b) => a.id - b.id)
      .filter(
        ({ step_status }) => step_status && step_status.key.includes('FINISHED')
      )
      .filter(
        ({ step_type }) => !['ADDITIONAL', 'EVOLUTION'].includes(step_type.key)
      )
      .map(({ step_type }) => {
        return step_type.key === 'FIRST_DATE'
          ? this.caseTrackingType.filter(
              ({ key }) => key === 'INITIAL_VALORATION'
            )[0]
          : this.caseTrackingType.filter(({ key }) => key === step_type.key)[0]
      })

    this.tableColumns = [
      {
        key: 'service_type.value',
        label: this.$t(
          `${this.consultResourcesElementName}.object.service_type`
        ),
        sortable: true,
      },
      {
        key: 'request_destination.value',
        label: this.$t(
          `${this.consultResourcesElementName}.object.request_destination`
        ),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.consultResourcesElementName}.object.actions`),
      },
    ]
    this.$root.$on('save-order', (medicineData) => {
      const index = _self.elementData.orders.findIndex(
        (element) => element.id == medicineData.id
      )
      if (index < 0) {
        _self.elementData.orders.push(medicineData)
        _self.all_internal_orders.push(medicineData)
      } else {
        _self.elementData.orders[index] = medicineData
        _self.all_internal_orders[index] = medicineData
      }
      console.log('Ordenes actualizadas: ', _self.elementData.orders)
    })
    this.followType = this.caseTrackingType.find(
      (element) => element.id === this.elementData.follow_type_id
    )
    switch (this.caseType) {
      case 'HEPA':
        this.followDate = moment(
          this.elementData.follow_date_1,
          'DD/MM/YYYY'
        ).toString()
        this.followDate2 = moment(
          this.elementData.follow_date_2,
          'DD/MM/YYYY'
        ).toString()
        this.followDate3 = moment(
          this.elementData.follow_date_3,
          'DD/MM/YYYY'
        ).toString()
        this.followDate4 = moment(
          this.elementData.follow_date_4,
          'DD/MM/YYYY'
        ).toString()
        this.requiresFollow = !!this.followDate
        break
      case 'COVID':
        this.followDate = moment(
          this.elementData.follow_schedule,
          'DD/MM/YYYY'
        ).toString()
        this.requiresFollow = this.elementData.requires_follow
        break
      case 'OTHER':
        this.followDate = moment(
          this.elementData.follow_schedule,
          'DD/MM/YYYY'
        ).toString()
        this.requiresFollow = !!(
          this.elementData.requieres_follow_id &&
          this.booleanList.find(
            (element) => this.elementData.requieres_follow_id === element.id
          ).key === 'YES'
        )
        break
      default:
        this.followDate = ''
        this.requiresFollow = false
        break
    }
  },
  methods: {
    get_delete_button_id(key) {
      return `delete_button${key}`
    },
    get_edit_button_id(key) {
      return `edit_button${key}`
    },
    edit_order(item) {
      this.orderData = item
      switch (item.service_type.key) {
        case 'FOLLOWUPS':
          this.$root.$emit('bv::show::modal', 'modal-medical-consult')
          break
        case 'LABORATORIES':
          this.$root.$emit('bv::show::modal', 'modal-clinical-laboratory')
          break
        case 'MEDICINES':
          this.$root.$emit('bv::show::modal', 'modal-medicines')
          break
        case 'VACCINATION':
          this.$root.$emit('bv::show::modal', 'modal-vaccines')
          break
        case 'INTERCONSULT':
          this.$root.$emit('bv::show::modal', 'modal-consults')
          break
      }
    },
    delete_order(item) {
      item.status = 0
    },
    proposedDate(item) {
      const self = this
      const follow_key = this.caseTrackingType.find(
        ({ id }) => id === item?.follow_type_id
      ).key

      if (follow_key) {
        switch (follow_key) {
          case 'INITIAL_VALORATION':
            self.proposed_date = item.follow_date_1 ? item.follow_date_1 : ''
            break
          case 'FIRST_FOLLOW':
            self.proposed_date = item.follow_date_2 ? item.follow_date_2 : ''
            break
          case 'SECOND_FOLLOW':
            self.proposed_date = item.follow_date_3 ? item.follow_date_3 : ''
            break
          case 'THIRD_FOLLOW':
            self.proposed_date = item.follow_date_4 ? item.follow_date_4 : ''
            break
          default:
            self.proposed_date = ''
            break
        }
      }
    },
    addMedicalConsult() {
      const _self = this
      this.orderData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        isNew: true,
        service_type: _self.serviceTypeList.find(
          (element) => element.key == 'FOLLOWUPS'
        ),
        service: {},
        proposed_date: _self.proposed_date,
      }
      this.$root.$emit('bv::show::modal', 'modal-medical-consult')
    },
    addMedicalLaboratory() {
      const _self = this
      this.orderData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        vaccineNew: true,
        service_type: _self.serviceTypeList.find(
          (element) => element.key == 'LABORATORIES'
        ),
        selected_services: [],
        services: [],
        proposed_date: _self.proposed_date,
      }
      this.$root.$emit('bv::show::modal', 'modal-clinical-laboratory')
    },
    addMedicine() {
      const _self = this
      this.orderData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        isNew: true,
        service_type: _self.serviceTypeList.find(
          (element) => element.key == 'MEDICINES'
        ),
        services: [],
      }

      for (const med of this.elementData.services) {
        if (!med.medicineProcesed) {
          this.orderData.services.push({ ...med, selected_id: med.id })
          med.medicineProcesed = true
        }
      }

      this.$root.$emit('bv::show::modal', 'modal-medicines')
    },
    addVaccine() {
      const _self = this
      this.orderData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        vaccineNew: true,
        service_type: _self.serviceTypeList.find(
          (element) => element.key == 'VACCINATION'
        ),
        services: [],
        accident_date: this.elementData.accident_date,
      }
      this.$root.$emit('bv::show::modal', 'modal-vaccines')
    },
    addConsult() {
      const _self = this
      this.orderData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        isNew: true,
        service_type: _self.serviceTypeList.find(
          (element) => element.key == 'INTERCONSULT'
        ),
        services: [],
      }
      this.$root.$emit('bv::show::modal', 'modal-consults')
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
